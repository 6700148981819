const getToken = () => {
  let arr = localStorage.getItem('tokenuser')
  return arr;
}

const getHeaders = () => {
  return {
    "blade-auth": 'bearer ' + getToken()
  };
}

const getJsonHeaders = () => {
  return {
    "blade-auth": 'bearer ' + getToken(),
    "content-type": "application/json;charset=UTF-8"
  };
}

const getFileUrl = (name) => {
  return window.location.origin + "/" + name;
}

export {
  getToken,
  getHeaders,
  getJsonHeaders,
  getFileUrl
}